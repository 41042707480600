import axios from 'axios';
import { ToastNotification } from '@/scripts/utils';
import { ToastProgrammatic as Toast } from 'buefy';
import { DialogProgrammatic as Dialog } from 'buefy';

const DefaultState = () => {
    return {
        base_url: null,
        gallery_url: null,
        close_modal: false,
        auth: {
            profile: {},
            token: null,
            isLoggedIn: false
        },
        headers: {
            headers: {
                Authorization: ''
            }
        },
        fileHeaders: null,
        formHeaders: { headers: { "Content-Type": "multipart/form-data" } },
    };
},
    state = DefaultState(),
    getters = {
        base_url: state => state.base_url,
        gallery_url: state => state.gallery_url,
        close_modal: state => state.close_modal,
        auth: state => state.auth,
        headers: state => state.headers,
        fileHeaders: state => state.fileHeaders,
        formHeaders: state => state.formHeaders,
    },
    mutations = {
        setBaseUrl(state) {
            state.base_url = process.env.VUE_APP_BASE_URL;
            state.gallery_url = process.env.VUE_APP_BASE_URL + 'api/uploads/gallery/';
        },
        setCloseModal(state) {
            state.close_modal = !state.close_modal;
            setTimeout(() => {
                state.close_modal = !state.close_modal;
            }, 1000);
        },
        setAuthData(state, payload) {
            state.auth.profile = payload.data.profile;
            state.auth.token = payload.data.token;
            state.auth.isLoggedIn = true;
            window.localStorage.setItem('profile', JSON.stringify(state.auth.profile));
            window.localStorage.setItem('token', JSON.stringify(state.auth.token));
            window.localStorage.setItem('isLoggedIn', true);
            state.headers.headers.authorization = `Bearer ${payload.data.token}`.replaceAll('"', '');
            state.fileHeaders = { 'Authorization': `Bearer ${payload.data.token}`, 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' };
        },
        resetAuth(state) {
            state.auth.profile = {};
            state.auth.token = null;
            state.auth.isLoggedIn = false;
            state.headers = {
                headers: {
                    autorization: ''
                }
            };
            state.fileHeaders = null;
        },
        setSessionData(state) {
            state.auth.isLoggedIn = window.localStorage.getItem('isLoggedIn') == 'true' ? true : false;
            if (state.auth.isLoggedIn) {
                state.auth.profile = window.localStorage.getItem('profile');
                state.auth.token = window.localStorage.getItem('token');
                state.headers.headers.authorization = `Bearer ${state.auth.token}`.replaceAll('"', '');
                state.fileHeaders = { 'Authorization': `Bearer ${state.auth.token}`, 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' };
            }
        }
    },
    actions = {
        async setBaseUrl({ commit }) {
            await commit('setBaseUrl');
        },
        setCloseModal({ commit }) {
            commit('setCloseModal');
        },
        toastNotification({ commit }, payload) {
            Toast.open({
                duration: 3000,
                message: payload.message,
                position: 'is-top',
                type: payload.type
            });
        },
        dialogNotification({ commit }, payload) {
            Dialog.alert({
                title: payload.title,
                message: payload.message,
                type: payload.type,
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            });
        },
        async setAuthData({ commit }, payload) {
            await commit('setAuthData', payload);
        },
        finishSession({ commit, dispatch }) {
            window.localStorage.clear();
            window.localStorage.setItem('isLoggedIn', false);
            commit('resetAuth');
        },
        checkSession({ commit }) {
            const active_session = window.localStorage.getItem('isLoggedIn') == 'true' ? true : false;
            if (active_session !== null && active_session) {
                commit('setSessionData');
            } else {
                window.localStorage.setItem('isLoggedIn', false);
            }
        },
    };

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
