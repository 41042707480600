import axios from 'axios';
import { ToastNotification } from '@/scripts/utils';

const DefaultState = () => {
    return {
        products: [],
        issues: [],
        questions: [],
        productOperationFlag: false,
        config: [],
        messages: [],
        requests: [],
        partners: []
    };
},
    state = DefaultState(),
    getters = {
        products: state => state.products,
        productOperationFlag: state => state.productOperationFlag,
        config: state => state.config,
        issues: state => state.issues,
        questions: state => state.questions,
        messages: state => state.messages,
        requests: state => state.requests,
        partners: state => state.partners
    },
    mutations = {
        setProducts(state, payload) {
            state.products = payload;
        },
        setProductOperationFlag(state, payload) {
            state.productOperationFlag = payload;
        },
        setConfig(state, payload) {
            state.config = payload;
        },
        setIssues(state, payload) {
            state.issues = payload;
        },
        setQuestions(state, payload) {
            state.questions = payload;
        },
        setMessages(state, payload) {
            state.messages = payload.sort((a, b) => a.id < b.id ? 1 : -1);
        },
        setRequests(state, payload) {
            state.requests = payload.sort((a, b) => a.id < b.id ? 1 : -1);
        },
        setPartners(state, payload) {
            state.partners = payload;
        }
    },
    actions = {
        setProductOperationFlag({ commit }, payload) {
            commit('setProductOperationFlag', payload);
        },
        async getProductsForAdmin({ commit, dispatch, rootState }) {
            if (!(rootState.global.base_url)) {
                dispatch('global/setBaseUrl', {}, { root: true });
            }
            await axios.get(`${rootState.global.base_url}api/admin/products`, rootState.global.headers)
                .then(async res => {
                    await commit('setProducts', res.data.data);
                })
                .catch(err => {
                    commit('setProducts', []);
                });
        },
        async submitNewProduct({ commit, dispatch, rootState }, payload) {
            await axios.post(`${rootState.global.base_url}api/admin/product`, payload, rootState.global.headers)
                .then(res => {
                    if (res.status == 200) {
                        dispatch('getProductsForAdmin');
                        commit('setProductOperationFlag', true);
                        dispatch('global/toastNotification', { type: 'is-success', message: 'El producto se ha creado correctamente' }, { root: true });
                    }
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error' }, { root: true });
                });
        },
        async updateProduct({ commit, dispatch, rootState }, payload) {
            delete payload.created_at;
            delete payload.updated_at;
            await axios.put(`${rootState.global.base_url}api/admin/product`, payload, rootState.global.headers)
                .then(res => {
                    if (res.status == 200) {
                        dispatch('global/setCloseModal', {}, { root: true });
                        dispatch('getProductsForAdmin');
                        dispatch('global/toastNotification', { type: 'is-success', message: 'El producto se ha actualizado correctamente' }, { root: true });
                    }
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error!' }, { root: true });
                });
        },
        async getAllPartners({commit, dispatch,  rootState}) {
            await axios.get(`${rootState.global.base_url}api/admin/partners`, rootState.global.headers)
                .then(res => {
                    if (res.status == 200) {
                        commit('setPartners', res.data.data);
                    }
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error' }, { root: true });
                });
        },
        async createPartner({ commit, dispatch, rootState }, payload) {
            await axios.post(`${rootState.global.base_url}api/admin/partners`, payload, rootState.global.headers)
                .then(res => {
                    if (res.status == 200) {
                        dispatch('global/setCloseModal', {}, { root: true });
                        dispatch('getAllPartners');
                        commit('setProductOperationFlag', true);
                        dispatch('global/toastNotification', { type: 'is-success', message: 'El partner se ha agregado correctamente' }, { root: true });
                    }
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error' }, { root: true });
                });
        },
        async submitPartnerDeletion({ dispatch, rootState }, payload) {
            const headers = { headers: { Authorization: rootState.global.headers.headers.authorization } };
            await axios.delete(`${rootState.global.base_url}api/admin/partners`, { data: payload, ...headers })
                .then(res => {
                    dispatch('getAllPartners');
                    ToastNotification('is-success', 'El partner ha sido eliminado.');
                })
                .catch(err => {
                    ToastNotification('is-danger', 'Ha ocurrido un error');
                });
        },
        async updatePartner({ commit, dispatch, rootState }, payload) {
            await axios.put(`${rootState.global.base_url}api/admin/partners`, payload, rootState.global.headers)
                .then(res => {
                    if (res.status == 200) {
                        dispatch('global/setCloseModal', {}, { root: true });
                        dispatch('getAllPartners');
                        dispatch('global/toastNotification', { type: 'is-success', message: 'El producto se ha actualizado correctamente' }, { root: true });
                    }
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error!' }, { root: true });
                });
        },
        async updateProductData({ commit, dispatch, rootState }, payload) {
            await axios.put(`${rootState.global.base_url}api/admin/product/quoters`, payload, rootState.global.headers)
                .then(res => {
                    if (res.status == 200) {
                        dispatch('global/setCloseModal', {}, { root: true });
                        dispatch('getProductsForAdmin');
                        dispatch('global/toastNotification', { type: 'is-success', message: 'El producto se ha actualizado correctamente' }, { root: true });
                    }
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error!' }, { root: true });
                });
        },
        async submitProductDeletion({ commit, dispatch, rootState }, payload) {
            const headers = { headers: { Authorization: rootState.global.headers.headers.authorization } };
            await axios.delete(`${rootState.global.base_url}api/admin/product`, { data: payload, ...headers })
                .then(res => {
                    dispatch('getProductsForAdmin');
                    ToastNotification('is-success', 'El producto ha sido eliminado.');
                })
                .catch(err => {
                    ToastNotification('is-danger', 'Ha ocurrido un error');
                });
        },
        async getConfig({ commit, rootState }) {
            await axios.get(`${rootState.global.base_url}api/admin/config`)
                .then(res => {
                    commit('setConfig', res.data);
                })
                .catch(err => console.log(err));
        },
        async updateCounter({ rootState }) {
            await axios.get(`${rootState.global.base_url}api/admin/config/counter`)
                .then(res => {})
                .catch(err => console.log(err));
        },
        async updateAbout({ commit, dispatch, rootState }, payload) {
            await axios.post(`${rootState.global.base_url}api/admin/config`, { field: 'about', data: payload })
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'La informacion ha sido actualizada correctamente' }, { root: true });
                    dispatch('getConfig');
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, por favor intente nuevamente.' }, { root: true });
                });
        },
        async updateConfig({ commit, dispatch, rootState }, payload) {
            await axios.post(`${rootState.global.base_url}api/admin/config`, payload)
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'La informacion ha sido actualizada correctamente' }, { root: true });
                    dispatch('getConfig');
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, por favor intente nuevamente.' }, { root: true });
                });
        },
        async getIssues({ commit, dispatch, rootState }) {
            await axios.get(`${rootState.global.base_url}api/admin/accidents`, rootState.global.headers)
                .then(res => {
                    commit('setIssues', res.data.data);
                })
                .catch(err => console.log(err));
        },
        async createIssue({ commit, dispatch, rootState }, payload) {
            dispatch('global/toastNotification', { type: 'is-success', message: 'Enviando datos' }, { root: true });
            await axios.post(`${rootState.global.base_url}api/admin/accident`, payload, rootState.global.headers)
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha creado el registro' }, { root: true });
                    dispatch('getIssues');
                    dispatch('global/setCloseModal', {}, { root: true });
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async updateIssue({ commit, dispatch, rootState }, payload) {
            dispatch('global/toastNotification', { type: 'is-success', message: 'Enviando datos' }, { root: true });
            await axios.put(`${rootState.global.base_url}api/admin/accident`, payload, rootState.global.headers)
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha actualizado el registro' }, { root: true });
                    dispatch('getIssues');
                    dispatch('global/setCloseModal', {}, { root: true });
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async deleteIssue({ commit, dispatch, rootState }, payload) {
            const headers = { headers: { Authorization: rootState.global.headers.headers.authorization } };
            dispatch('global/toastNotification', { type: 'is-success', message: 'Procesando...' }, { root: true });
            await axios.delete(`${rootState.global.base_url}api/admin/accident`, { data: payload, ...headers })
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha eliminado el registro' }, { root: true });
                    dispatch('getIssues');
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async sendDeleteRecord({ commit, dispatch, rootState }, payload) {
            const headers = { headers: { Authorization: rootState.global.headers.headers.authorization } };
            dispatch('global/toastNotification', { type: 'is-success', message: 'Procesando...' }, { root: true });
            await axios.delete(`${rootState.global.base_url}api/admin/requests`, { data: payload, ...headers })
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha eliminado el registro' }, { root: true });
                    dispatch('getRequests');
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async getQuestions({ commit, dispatch, rootState }) {
            await axios.get(`${rootState.global.base_url}api/admin/faqs`, rootState.global.headers)
                .then(res => {
                    commit('setQuestions', res.data.data);
                })
                .catch(err => console.log(err));
        },
        async createQuestion({ commit, dispatch, rootState }, payload) {
            dispatch('global/toastNotification', { type: 'is-success', message: 'Enviando datos' }, { root: true });
            await axios.post(`${rootState.global.base_url}api/admin/faq`, payload, rootState.global.headers)
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha creado el registro' }, { root: true });
                    dispatch('getQuestions');
                    dispatch('global/setCloseModal', {}, { root: true });
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async updateQuestion({ commit, dispatch, rootState }, payload) {
            dispatch('global/toastNotification', { type: 'is-success', message: 'Enviando datos' }, { root: true });
            await axios.put(`${rootState.global.base_url}api/admin/faq`, payload, rootState.global.headers)
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha actualizado el registro' }, { root: true });
                    dispatch('getQuestions');
                    dispatch('global/setCloseModal', {}, { root: true });
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async deleteQuestion({ commit, dispatch, rootState }, payload) {
            const headers = { headers: { Authorization: rootState.global.headers.headers.authorization } };
            dispatch('global/toastNotification', { type: 'is-success', message: 'Procesando...' }, { root: true });
            await axios.delete(`${rootState.global.base_url}api/admin/faq`, { data: payload, ...headers })
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha eliminado el registro' }, { root: true });
                    dispatch('getQuestions');
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async getMessages({ commit, rootState }) {
            await axios.get(`${rootState.global.base_url}api/admin/messages`, rootState.global.headers)
                .then(res => {
                    commit('setMessages', res.data.data);
                })
                .catch(err => console.log(err));
        },
        async setReadMessage({ commit, dispatch, rootState }, payload) {
            await axios.post(`${rootState.global.base_url}api/admin/message/read`, payload, rootState.global.headers)
                .then(res => {
                    dispatch('getMessages');
                })
                .catch(err => console.log(err));
        },
        async setUnreadMessage({ commit, dispatch, rootState }, payload) {
            await axios.post(`${rootState.global.base_url}api/admin/message/unread`, payload, rootState.global.headers)
                .then(res => {
                    dispatch('getMessages');
                })
                .catch(err => console.log(err));
        },
        async deleteMessage({ commit, dispatch, rootState }, payload) {
            const headers = { headers: { Authorization: rootState.global.headers.headers.authorization } };
            dispatch('global/toastNotification', { type: 'is-success', message: 'Procesando...' }, { root: true });
            await axios.delete(`${rootState.global.base_url}api/admin/message/delete`, { data: payload, ...headers })
                .then(res => {
                    dispatch('global/toastNotification', { type: 'is-success', message: 'Se ha eliminado el registro' }, { root: true });
                    dispatch('global/setCloseModal', {}, { root: true });
                    dispatch('getMessages');
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
        async getRequests({ commit, rootState }) {
            await axios.get(`${rootState.global.base_url}api/admin/requests`, rootState.global.headers)
                .then(res => {
                    commit('setRequests', res.data.data);
                })
                .catch(err => console.log(err));
        },
        async updateRequestData({ commit, dispatch, rootState }, payload) {
            await axios.put(`${rootState.global.base_url}api/admin/request`, payload, rootState.global.headers)
                .then(res => {
                    dispatch('getRequests');
                    dispatch('global/toastNotification', { type: 'is-success', message: 'La informacion ha sido actualizada' }, { root: true });
                })
                .catch(err => {
                    dispatch('global/toastNotification', { type: 'is-danger', message: 'Ha ocurrido un error, intente nuevamente' }, { root: true });
                });
        },
    };

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
