<template lang ="pug">
b-navbar#navbar(:fixed-top="true")
	template(#brand)
		b-navbar-item(tag="router-link" :to="{ path: '/' }")
			img(
				:src="require('@/assets/logo.png')"
				alt="Aseguradoos")
	template(#start)
		template(v-for="(option, key) in options")
			b-navbar-dropdown(v-if="option.has_option" :label="option.name" :key="key")
				b-navbar-item(v-for="(op, index) in option.options" tag="router-link" :key="index" :to="{path: op.path}") {{op.name}}
			b-navbar-item(v-else :key="key" tag="router-link" :to="{path: option.uri}") {{option.name}}
		b-navbar-item(tag="router-link" :to="{path: '/admin'}" v-if="isLoggedIn") Administrar
	template(#end)
		b-navbar-item
			b-button.as-btn-primary-light(icon-left="email-outline" @click="toggleContactModal(true)") Contacto
</template>

<script>
 import { mapGetters, mapActions } from 'vuex';

 export default {
		 data() {
				 return {
						 isLoggedIn: false
				 }
		 },
		 mounted() {
				 this.loadInitialData()
		 },
		 watch: {
				 'auth.isLoggedIn': function(nv) {
						 this.isLoggedIn = nv
				 }
		 },
		 computed: {
				 ...mapGetters('web', [
						 'products',
						 'options',
						 'productOptions',
						 'issuesOptions'
				 ]),
				 ...mapGetters('global', [
						 'auth'
				 ])
		 },
		 methods: {
				 ...mapActions('web', [
						 'setProductOptions',
						 'setIssuesOptions',
						 'getProducts',
						 'toggleContactModal',
						 'toggleAuthModal',
						 'getIssues',
						 'getFaqs'
				 ]),
				 ...mapActions('global', [
						 'finishSession',
						 'checkSession',
						 'toastNotification'
				 ]),
				 async loadInitialData() {
						 await this.checkSession();
						 await this.getProducts();
						 await this.setProductOptions();
						 await this.getIssues();
						 await this.getFaqs();
						 await this.setIssuesOptions();
						 const productsIndex = this.options.findIndex(option => option.name == 'Productos');
						 const issuesIndex = this.options.findIndex(option => option.name == 'Siniestros');
						 this.options[productsIndex].options = this.productOptions;
						 this.options[issuesIndex].options = this.issuesOptions;
						 this.isLoggedIn = this.auth.isLoggedIn
				 }
		 }
 }
</script>

<style lang="scss">
 #navbar {
	 margin-bottom: 80px
 }
</style>
