import AdminLayout from '../../layouts/AdminLayout.vue';

const admin = [
	{
		path: '/admin',
		component: AdminLayout,
		children: [
			{
				path: '/',
				name: 'admin-index',
				component: () => import('@/views/admin/IndexView.vue')
			},
			{
				path: '/admin/productos',
				name: 'admin-productos',
				component: () => import('@/views/admin/ProductsView.vue')
			},
			{
				path: '/admin/siniestros',
				name: 'admmin-siniestros',
				component: () => import('@/views/admin/IssuesView.vue')
			},
			{
				path: '/admin/about',
				name: 'admin-about',
				component: () => import('@/views/admin/AboutView.vue')
			},
			{
				path: '/admin/terms',
				name: 'admin-terms',
				component: () => import('@/views/admin/TermsView.vue')
			},
			{
				path: '/admin/security',
				name: 'admin-security',
				component: () => import('@/views/admin/SecurityView.vue')
			},
			{
				path: '/admin/privacy',
				name: 'admin-privacy',
				component: () => import('@/views/admin/PrivacyView.vue')
			},
			{
				path: '/admin/contact',
				name: 'admin-contact',
				component: () => import('@/views/admin/ContactView.vue')
			},
			{
				path: '/admin/conditions',
				name: 'admin-conditions',
				component: () => import('@/views/admin/ConditionsView.vue')
			},
			{
				path: '/admin/faq',
				name: 'admin-faq',
				component: () => import('@/views/admin/FaqView.vue')
			},
			{
				path: '/admin/messages',
				name: 'admin-messages',
				component: () => import('@/views/admin/MessagesView.vue')
			},
			{
				path: '/admin/requests',
				name: 'admin-requests',
				component: () => import('@/views/admin/RequestsView.vue')
			},
			{
				path: '/admin/usuarios',
				name: 'admin-usuarios',
				component: () => import('@/views/admin/UsersView.vue')
			},
			{
				path: '/admin/quoters',
				name: 'quoters',
				component: () => import('@/views/admin/QuotersView.vue')
			},
			{
				path: '/admin/partners',
				name: 'partners',
				component: () => import('@/views/admin/PartnersView.vue')
			}
		]
	}
];

export default admin;
