import axios from 'axios';
import { ToastNotification } from '@/scripts/utils';

const DefaultState = () => {
		return {
				credentials: {
						code: '13276',
						userName: '21005740ws',
						password: 'HiWl2dUVOF'
				},
				base_url: 'https://sis.rus.com.ar',
				rus_token: null,
				selected_vehicle: null,
				brands: [],
				quotes: [],
				selected_quote: null,
				years: [
						2004,
						2005,
						2006,
						2007,
						2008,
						2009,
						2010,
						2011,
						2012,
						2013,
						2014,
						2015,
						2016,
						2017,
						2018,
						2019,
						2020,
						2021,
						2022,
						2023
				],
				codigoProductor: 13276,
				models: [],
				versions: [],
				localidades: [],
				headers: {
						headers: {
								Authorization: null
						}
				},
				request_status: 'waiting',
				is_moto: false,
				close_modal: false
		};
},
		state = DefaultState(),
		getters = {
				rus_token: state => state.rus_token,
				brands: state => state.brands,
				years: state => state.years,
				models: state => state.models,
				versions: state => state.versions,
				request_status: state => state.request_status,
				localidades: state => state.localidades,
				codigoProductor: state => state.codigoProductor,
				quotes: state => state.quotes,
				selected_vehicle: state => state.selected_vehicle,
				selected_quote: state => state.selected_quote,
				is_moto: state => state.is_moto,
				headers: state => state.headers,
				close_modal: state => state.close_modal
		},
		mutations = {
				setToken(state, payload) {
						state.rus_token = payload;
						state.headers.headers['Authorization'] = `${state.rus_token}`;
				},
				setIsMoto(state, payload) {
						state.is_moto = payload;
				},
				setBrands(state, payload) {
						state.brands = payload;
				},
				setRequestStatus(state, payload) {
						state.request_status = payload;
				},
				setModels(state, payload) {
						state.models = payload;
				},
				setVersions(state, payload) {
						state.versions = payload;
				},
				setLocalidades(state, payload) {
						state.localidades = payload;
				},
				setQuotes(state, payload) {
						payload.forEach(quote => {
								if (!quote.descripcionComercial) {
										quote.descripcionComercial = quote.codigoRC;
										quote.riesgo = '';
										quote.riesgos = [];
								}
								state.quotes.push(JSON.parse(JSON.stringify(quote)));
						});
				},
				setSelectedCar(state, payload) {
						state.selected_vehicle = JSON.parse(JSON.stringify(payload));
				},
				resetSearch(state) {
						state.quotes = [];
						state.selected_vehicle = null;
						state.selected_quote = null;
				},
				setSelectedQuote(state, payload) {
						state.selected_quote = JSON.parse(JSON.stringify(payload));
				},
				closeModal(state, payload) {
						state.close_modal = payload;
				}
		},
		actions = {
				setIsMoto({ commit }, payload) {
						commit('setIsMoto', payload);
				},
				closeModal({ commit }, payload) {
						commit('closeModal', payload);
				},
				resetSearch({ commit }) {
						commit('resetSearch');
				},
				async rusLogin({ commit, state, dispatch }) {
						commit('setRequestStatus', 'loading');
						await axios.post(`${state.base_url}/api-rus/login/token`, state.credentials)
								.then(res => {
										commit('setToken', res.data.message);
										if (state.is_moto) {
												dispatch('rusGetMotoBrands');
										} else {
												dispatch('rusGetBrands');
										}
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										commit('setRequestStatus', 'error');
										console.log(err);
								});
				},
				async rusGetBrands({ commit, state }) {
						commit('setRequestStatus', 'loading');
						await axios.get(`${state.base_url}/api-rus/vehiculos/marcas?TipoUnidad=1`, state.headers)
								.then(res => {
										commit('setBrands', res.data.dtoList);
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										console.log(err);
										commit('setRequestStatus', 'error');
								});
				},
				async rusGetModels({ commit, state, dispatch }, payload) {
						commit('setRequestStatus', 'loading');
						await axios.get(`${state.base_url}/api-rus/vehiculos/${payload}`, state.headers)
								.then(res => {
										if (res.data.dtoList === null) {
												commit('setModels', []);
												dispatch('global/dialogNotification', { title: 'Error', type: 'is-danger', message: `No existen modelos del vehiculo seleccionado en ese año` }, { root: true });
										} else {
												commit('setModels', res.data.dtoList);
										}
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										commit('setRequestStatus', 'error');
								});
				},
				async rusGetVersions({ commit, state }, payload) {
						commit('setRequestStatus', 'loading');
						await axios.get(`${state.base_url}/api-rus/vehiculos/${payload}`, state.headers)
								.then(res => {
										commit('setVersions', res.data.dtoList);
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										commit('setRequestStatus', 'error');
								});
				},
				async rusGetLocalidades({ commit, state, dispatch }, payload) {
						commit('setLocalidades', []);
						commit('setRequestStatus', 'loading');
						await axios.get(`${state.base_url}/api-rus/localidades/${payload}`, state.headers)
								.then(res => {
										if (res.data.cantidadTotal > 0) {
												commit('setLocalidades', res.data.dtoList);
										} else {
												dispatch('global/dialogNotification', { title: 'Error', type: 'is-danger', message: `El codigo postal es invalido, por favor verifique los datos.` }, { root: true });
										}
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										dispatch('global/dialogNotification', { title: 'Error', type: 'is-danger', message: `El codigo postal es invalido, por favor verifique los datos.` }, { root: true });
										commit('setRequestStatus', 'error');
								});
				},
				rusSetSelectedCar({ commit }, payload) {
						commit('setSelectedCar', payload);
				},
				rusSetSelectedVehicle({ commit }, payload) {
						commit('setSelectedCar', payload);
				},
				async rusSendQuoteRequest({ commit, state }, payload) {
						commit('setQuotes', []);
						commit('setRequestStatus', 'loading');
						var url = '';
						if (state.is_moto) {
								url = `${state.base_url}/api-rus/cotizaciones/motosconriesgo`;
						} else {
								url = `${state.base_url}/api-rus/cotizaciones/autosconriesgo`;
						}
						await axios.put(url, payload, state.headers)
								.then(res => {
										commit('setQuotes', res.data.dtoList);
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										commit('setRequestStatus', 'error');
								});
				},
				setQuotePreview({ commit }, payload) {
						commit('setSelectedQuote', payload);
				},
				async submitQuoteRequest({ commit, state, dispatch, rootState }, payload) {
						commit('setRequestStatus', 'loading');
						await axios.post(`${rootState.global.base_url}api/web/requests/new`, payload, state.credentials)
								.then(res => {
										commit('closeModal', true);
										commit('resetSearch');
										dispatch('setQuotePreview', null);
										dispatch('global/dialogNotification', { title: 'Operacion realizada correctamente', type: 'is-success', message: `Su solicitud ha sido enviada correctamente, nos comunicaremos a la brevedad. Muchas gracias!.` }, { root: true });
								})
								.catch(err => console.log(err));
						commit('setRequestStatus', 'waiting');
				},
				async rusGetMotoBrands({ commit, state }) {
						commit('setRequestStatus', 'loading');
						await axios.get(`${state.base_url}/api-rus/vehiculos/marcas?TipoUnidad=8`, state.headers)
								.then(res => {
										commit('setBrands', res.data.dtoList);
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										commit('setRequestStatus', 'error');
								});
				},
				async rusGetMotoModels({ commit, state, dispatch }, payload) {
						commit('setRequestStatus', 'loading');
						await axios.get(`${state.base_url}/api-rus/vehiculos/${payload}`, state.headers)
								.then(res => {
										if (res.data.dtoList === null) {
												commit('setModels', []);
												dispatch('global/dialogNotification', { title: 'Error', type: 'is-danger', message: `No existen modelos del vehiculo seleccionado en ese año` }, { root: true });
										} else {
												commit('setModels', res.data.dtoList);
										}
										commit('setRequestStatus', 'waiting');
								})
								.catch(err => {
										commit('setRequestStatus', 'error');
								});
				},
				rusResetSearch({ commit }) {
						commit('resetSearch');
				}
		};

export default {
		namespaced: true,
		state,
		getters,
		mutations,
		actions
};
